<script setup lang="ts">
import LinkButton from "~/localComponents/headerBar/LinkButton.vue";

const show = ref(true);
</script>

<template>
  <UButton
    v-if="!show"
    class="absolute -top-2 left-1/2 z-10 opacity-20 ring-0 transition-all duration-1000 ease-out hover:top-0 hover:opacity-100"
    color="gray"
    variant="solid"
    size="2xs"
    icon="i-mdi-arrow-expand-down"
    @click="show = true"
    title="Show the header bar"
  />
  <header
    class="relative flex h-fit min-h-[4rem] flex-shrink-0 flex-wrap items-center justify-between gap-1 overflow-hidden border-b px-4"
    :class="{ hidden: !show }"
  >
    <UButton
      class="absolute -bottom-3 left-1/2 opacity-10 ring-0 hover:opacity-80"
      color="gray"
      variant="solid"
      size="2xs"
      icon="i-mdi-arrow-collapse-up"
      @click="show = false"
      title="Hide the header bar"
    />
    <div class="flex flex-wrap items-center gap-2">
      <ULink to="/" title="Embbay home page"
        ><LogoLongSvg class="ml-2 min-w-[81px]"
      /></ULink>
      <div class="ml-2 flex h-full flex-wrap items-end gap-2">
        <LinkButton to="/workshop">Workshop</LinkButton>
        <LinkButton to="/public/docs">Docs </LinkButton>
      </div>
    </div>
    <div class="flex items-center">
      <slot name="current-location" />
    </div>
  </header>
</template>
