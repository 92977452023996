<script setup lang="ts"></script>

<template>
  <UButton
    class="inline-flex flex-shrink-0 items-center gap-x-1.5 rounded-md bg-gray-50 px-2.5 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-100 focus:outline-none focus-visible:outline-0 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-gray-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:opacity-75 sm:gap-x-2.5 sm:px-3.5 sm:py-2.5 sm:text-base"
    v-bind="$attrs"
  >
    <slot />
  </UButton>
</template>
